// @ts-check

import { withinDateRange } from '../client/utils/withinDateRange';

/* host names */
export const tkCanonicalHost = 'https://www.theknot.com';
export const tkHost = 'https://www.theknot.com';
export const tkNext =
	'https://tk-marketplace-fe-nextjs-prod.k8s.localsolutions.theknot.com';

/* api keys */
export const geoApiKey = 'vkq9ckuqn9c7jprn92uwbsjkzmtbk6pdxh9';
export const weddingsApiKey = 'F2HB4UM97Www1YgSB2Nd85ueRqJIBprU';
export const membershipApiKey = 'xO42dFh78Uy5z351IDJwVuwYV86yZkWV';
export const analyticsWriteKey = '0KXGZo0hporpqj3UE8vz6GNa1U0GIs45';
export const reviewsApiKey = 'abusHgT1FntDGOHS9sJo9WpoNmldKBhPPqWMcpck';
export const mediaApiKey = '10750abefb7f4f0606a162225d8b93a5';

/* endpoints */
export const mediaApiUrl = 'https://media-api.xogrp.com/images/';
export const videoMediaApiUrl = 'https://media-api.xogrp.com/videos/';
export const noServicesUrl = 'https://no-services.theknot.com';
export const weddingsRootUrl = 'https://api.weddings.xogrp.com';
/* from George M. going forward there is no internal K8s url */
export const graphqlApiUrlK8 =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';

export const graphqlApiUrl =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';
export const reviewsApiUrl = `${noServicesUrl}/local-partners/api/reviews`;
export const reviewAiSummaryUrl =
	'https://review-summaries.dataops.nuptic.com/predict';
export const googleReviewsUrl =
	'https://pro-third-party-reviews-api-useast1.svc.nuptic.com/graphql';
export const taxonomyApi = 'https://api.dataintel.xogrp.com/taxonomy/knot';
export const experimentAPI =
	'https://api.experimentation.xogrp.com/experiments';
export const identityApi =
	'https://prod-identity-api.localsolutions.theknot.com/identities';
export const internalCreateImageReviewsUrl =
	'/marketplace/reviews/images/create';
export const internalDeleteImageReviewsUrl =
	'/marketplace/reviews/images/delete';

export const gtmId = 'GTM-KBVGRZX';
export const gaTrackingId = 'UA-776588-1';

export const tkProUrl = 'https://partnerssignup.theknot.com';
export const vendorManagerUrl = 'https://theknot.com/wedding-vendor-manager';

export const conversationsApi = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations`;
export const conversationsAuthApi = `${noServicesUrl}/local-partners/api/app-auth/auth/member`;
export const transactionsAPIUrl = `${noServicesUrl}/local-partners/api/transactions`;
export const bulkInquireAPIUrl = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations/bulkInquire`;
export const inquireAPIUrl = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations/inquire`;
export const inboxUrl = `${tkHost}/inbox/conversations`;
export const semanticSearchApiUrl =
	'https://semantic-search.dataops.nuptic.com/predict';

/* api keys */
export const pvRecApiKey = 'de4c24647278487eb19195721cb35280';

/* gtm experiments */
export const hideFeaturedExperimentId = 'sh9nlHrLSyWTpZbNifXLSA';

/* experiment API keys */
export const experimentAPIKey = '26b222c7690da5916c5c62d92814f668';

const unknownId = 'UNKNOWN_PROD_ID';
/** @type Experiments.Settings */
export const experiments = {
	vrmMatchDetails: 'b1b20e31-76b6-4772-9cc7-c905f85b571d',
	refreshResults: 'efac327c-fadc-4d25-9609-aba8193ff115',
	mobileMapView: '248e6b05-892e-4090-8020-95122ce3534f',
	storefrontLightboxRevamp: '984a69ce-a894-4ce1-a5c3-a1f686ce3ccf',
	expectedValue: '32feb16c-a247-4344-bba4-59254b5aae97',
	recentlyViewed: '399ea62a-3eef-4df5-aee0-a58db4af6218',
	VRMLegalUpdateMobile: '45f26e65-7d1b-4c58-bd26-5ef103746f85',
	estimatedPricing: 'c4380756-93c2-4dd5-a113-df87107641cf',
	listingsSections: 'adc80152-389d-459e-9d2a-5007823c97c3',
	capacityFilter: '03de5794-af15-462a-933b-01be0ca107be',
	learnToRank: 'fb31758f-bcac-4c65-b380-1b5636ca2039',
	uiReadabilityEnhancements: 'd94e7336-6bc9-4e52-8333-83072afcd7af',
	userEngagementInteractivity: '66d249d5-fed4-4d82-9014-a667da759f90',
	storefrontSearch: '3891a5f3-95d8-4578-a9f7-fdfa9e0ef3d6',
	quickFilters: 'ac809575-5370-4405-90e0-c02ff42f877a',
	spotlightAd: '5c8ee158-b130-4369-8870-3883dff9b992',
	acrossCategorySearch: '0b3302b5-f8e2-4c10-86d3-c3f9e32869a5',
	venueStartingPrice: '2f7658e4-fa5c-4118-aab0-eba5146341a4',
};

// If your new experiment is a backend experiment add it to this array
// This array of keys will be used to add the assignment when generating a hash key for search results
/** @type {string[]} */
export const backendExperimentKeys = ['fb31758f-bcac-4c65-b380-1b5636ca2039'];

export const awards = {
	bow: {
		beforeStartDate: {
			year: '2024',
			name: '2024 Best of Weddings',
			filterId: '0b541473-2e05-47e6-8dc2-b635477785fc',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: 'd15e4891-5b15-4e3e-826e-623b5df9b552',
		},
		afterStartDate: {
			year: '2025',
			name: '2025 Best of Weddings',
			filterId: '586476df-c2fa-4c98-879d-87297163f8d1',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: '9f56c034-2057-4a02-99ee-18cc8c516ba7',
		},
	},
	hof: {
		beforeStartDate: {
			year: '2024',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		},
		afterStartDate: {
			year: '2025',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		},
	},
	endDate: 'January 15, 2026 00:00:00 EST',
	startDate: 'January 15, 2025 03:00:00 EST',
};

/* base API URL */
export const xoAPIRootUrl = 'https://no-services.theknot.com';
export const marketplaceGraphqlUrl =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';
export const xoAPIReviewsStorefrontUrl = `${xoAPIRootUrl}/local-partners/api/reviews/storefront`;
export const xoAPIReviewsMemberUrl = `${xoAPIRootUrl}/local-partners/api/reviews/member`;

export const googleMapsAPIKey = 'AIzaSyD4yMSGEidjsEuFn_M0dQ5Nh25IPa4vla8';

/* hosts */
export const bluecardRootUrl = 'https://prod.bluecard.theknot.com';

/* endpoints */
export const bluecardWeddingVisionExistsUrl = `${bluecardRootUrl}/api/v1/users/`;
export const bulkInquiryUrl = `${xoAPIRootUrl}/local-partners/api/conversation-theknot/conversations/bulkInquire`;

const BOW_CURRENT_WINNER_ID = withinDateRange({
	startDate: awards.startDate,
})
	? awards.bow.afterStartDate.filterId
	: awards.bow.beforeStartDate.filterId;

export const SearchConstants = {
	DEFAULT_LIMIT: 30,
	BOW_CURRENT_WINNER_ID,
	BOW_HALL_OF_FAME_ID: awards.hof.beforeStartDate.filterId,
	BOW_FILTER_SLUG: 'best-of-weddings',
	BOW_MEDAL_MEDIA_ID: 'b87d3db7-35c3-4a97-87db-1059c238c37d',
	INTERNAL_REFERRER_SORT: 'featured',
};

export const adSlotEnv = 'production';

export const gpFeatureFlagEndpointUrl = 'https://www.weddingpro.com';

export const gpBaseUrl = 'https://www.weddingwire.com';
